import React, { useState } from 'react'
import Helmet from 'react-helmet'
import './style.css'

const CONTACT_LINKS = [
  { name: 'Github', uri: 'https://github.com/vivekagr' },
  { name: 'LinkedIn', uri: 'https://www.linkedin.com/in/vivekagr0/' },
  { name: 'StackOverflow', uri: 'https://stackoverflow.com/users/2017947/vivekagr' },
  { name: 'Résumé', uri: '/resume.pdf'}
]

const EXPERIENCES = [
  {
    topics: ['Python', 'Flask', 'Django'],
    description: 'I have been working with Python since 2013, using Flask & Django - from traditional MVC style websites to API backends powering services and apps.'
  },
  {
    topics: ['Javascript', 'Typescript', 'React', 'React Native', 'Svelte'],
    description: 'Started with jQuery back in the day, then after trying Angular.js, Ember.js and others, I decided to stick with React+Redux -- I have over four years of experience with it.  I love using React Native, especially alongside a React.js webapp to leverage all the goodness of shared core.  Always up for trying something new, but the amount of churn in JS ecosystem & vast number of libraries going in and out of fashion each season sometimes goes against my focus on building long-lasting & performant apps with less maintenance overhead.  Svelte.js seems like an interesting framework, being compiled down to vanilla JS without any overhead of virtual DOM diffing.'
  },
  {
    topics: ['Postgres', 'Cassandra', 'Redis', 'RabbitMQ'],
    description: 'My default tools of trade for database, caching & message queue.  I also have experience w/ CockroachDB, MongoDB, MariaDB, Memcached.  Learning more about Kafka & ElasticSearch and always on the lookout to try new things.'
  },
  {
    topics: ['Microservices', 'Serverless', 'REST', 'GraphQL'],
    description: 'I enjoy working with micro-services architecture now to deliver performant & scalable services -- with preference for serverless deployments & small services -- communicating with each other using REST APIs & message queues.'
  },
  {
    topics: ['Docker', 'Ansible', 'AWS'],
    description: 'Docker is my go-to tool for setting up development environment (and for production).  I love using Ansible for the cases where bare metal deployment is preferred over containerization.  I prefer using AWS (shoutout to S3, EC2, RDS, Lambda, API Gateway, Cloudfront, Route 53) for deployments when I can -- I\'m also an AWS Certified Solutions Architect. But all I really need are Debian based servers from any provider to get things up and running.'
  },
  {
    topics: ['Certificate Authority', 'X.509', 'TLS Certificates'],
    description: 'Having worked with a Certificate Authority, designing & developing their PKI platform (Python/Flask/CockroachDB/Artemis), I have developed keen interest in the domain.'
  },
  {
    topics: ['WebRTC', 'Realtime P2P communication'],
    description: 'I have leveraged WebRTC to power P2P video & audio calls between React.js based webapp & React-Native based Android & iOS builds.'
  },
  {
    topics: ['Product Development'],
    description: 'After working with startups pretty much my whole career where I had to wear all the hats, I believe that I have developed a sense for product development and business side of things in general.  This translates into better engineering decisions with the end product & user in mind.'
  },
  {
    topics: ['Affiliate Marketing', 'SEO'],
    description: 'Before going full-time as a developer, I used to work as affiliate marketer for multiple networks. I have gained SEO knowledge as a part of the process of building websites, getting them ranked on search engines & monetising the traffic with ad & affiliate networks.  I still operate couple of websites getting over thousands of users a day.  While not strictly related to web development, these skills do prove helpful in making better decisions with the tech implementation.'
  },
  // {
  //   topics: ['Web Scraping'],
  //   description: ''
  // }
]

export default function Home() {
  const [descVisible, setDescVisible] = useState(false);

  return (
    <div className='font-body text-lg'>
      <Helmet title='Vivek Agarwal' defer={false} />

      <div className='container mx-auto p-4'>
        <div className='flex items-center my-3'>
          <img className='rounded-full h-16 w-16' alt='Vivek Agarwal' src='/vivek-agarwal.jpg' />
          <div className='ml-4'>
            <h1 className='font-light text-2xl lowercase text-gray-800'>Vivek Agarwal</h1>
            <div className='text-sm'>
              {CONTACT_LINKS.map(c => (
                <a key={c.name} className='mr-4 my-1 plain-link' href={c.uri} rel='noreferrer' target='_blank'>{c.name}</a>
              ))}
            </div>
          </div>
        </div>

        <div className='mt-10 prose max-w-none text-lg'>
          <h4>About Me</h4>
          <p>A full stack developer, here are some of the stuff that I have experience and interest in
            <span className='inline-block ml-2'>(
              <button onClick={() => setDescVisible(!descVisible)} className='inline-block mx-1 border-b leading-6 cursor-pointer text-blue-700 border-blue-200 hover:text-blue-700 hover:border-indigo-300 focus:outline-none transition-color duration-200'>
                {descVisible ? 'collapse' : 'expand'} details
              </button>)
            </span>
          </p>
          <ul>
            {EXPERIENCES.map((e, i) => (
              <li key={i}>
                {e.topics.map((t, i) => (<span key={t.toLowerCase()}><span className='inline-block'>{t}</span> {i < e.topics.length-1 && <span className='text-gray-500'>/</span>} </span>))}
                <span className={`block text-sm transition-max-height-color ease-out overflow-hidden ${descVisible ? 'max-h-visible text-gray-600 duration-1000' : 'max-h-hidden text-white duration-100'}`}>{e.description}</span>
              </li>
            ))}
          </ul>

          <p className={`text-xs text-gray-500 pl-8 ${descVisible ? 'hidden' : ''}`}>
            ...what a bunch of buzzwords, I know right?!&nbsp;&nbsp;
            <span className='text-gray-600'>maybe the <span className='plain-link cursor-pointer' onClick={() => setDescVisible(!descVisible)}>details</span> will provide some context</span>
          </p>
        </div>
      </div>
      <div className='bg-gray-100 mt-5 border-b-2 border-t border-gray-200'>
        <div className='container mx-auto px-4 pt-6 pb-6 text-blue-800'>
          <p className='text-blue-800'>Please reach out if I can be of any help. Or just say hi – <a href='mailto:vivek@vivekagr.com' className='plain-link'>vivek@vivekagr.com</a></p>
          {/* <div className='mt-4'>
            {CONTACT_LINKS.map(c => (
              <a key={c.name} className='mr-5 plain-link' href={c.uri} rel='noreferrer' target='_blank'>{c.name}</a>
            ))}
          </div> */}
        </div>
      </div>

      <footer>
        <div className='container mx-auto text-xs font-light p-3'>
          custom made with <a href='https://www.gatsbyjs.com/' rel='nofollow noreferrer' target='_blank'>Gatsby.js</a> & <a href='https://tailwindcss.com' rel='nofollow noreferrer' target='_blank'>TailwindCSS</a> / deployed on <a href='https://vercel.com/' rel='nofollow noreferrer' target='_blank'>Vercel</a>
        </div>
      </footer>
    </div>
  )
}
